.NavMenu {
  display: block !important;
}

.NavMenuLink > a {
  padding: 0;
  padding-bottom: 35px;
}

.NavMenuLink > a:last-child {
  border-bottom: none;
  padding: 0;
}

@media screen and (min-width: 200em) {
  .NavMenu {
    display: none !important;
  }
}
