/*
You do not need to worry about .error {} colliding with any other `.css` or
`.module.css` files!
*/
.modal {
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 200;
  background-color: rgba(252, 251, 251, 0.6);
  overflow-y: hidden;
}

.modal_div {
  display: block;
  position: relative;
  height: 297px;
  width: 415px;
  background-color: var(--white-one-off);
  border-radius: 5px;
}

.success {
  border: 1px solid #04802e;
}

.error {
  border: 1px solid #cb1a14;
}

.warning {
  border: 1px solid #ffcc4d;
}

.modal_close {
  color: var(--black-one-off);
  font-family: Mint Grotesk Display !important;
  font-size: 10px;
  font-style: normal;
  line-height: normal;
  position: relative;
  cursor: pointer;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 2px;
}

.modal_div_header {
  display: flex;
  justify-content: flex-end;
}

.modal_div_icon > svg {
  display: block;
  width: 66px;
}

.modal_div_body {
  gap: 0px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.modal_div_body > h3 {
  margin: 0;
  padding: 0;
  margin-top: 31px;
  color: var(--black-one-off);
  text-align: center;
  font-family: Mint Grotesk Display;
  font-size: 19px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
}

.modal_div_body > p {
  margin-top: 10px;
  margin-bottom: 40px;
  color: var(--black-one-off);
  text-align: center;
  font-family: Mint Grotesk Display;
  font-size: 12px;
  font-style: normal;
  line-height: 17px;
  text-transform: math-auto;
}

.modal_div_done {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black-one-off);
  text-align: center;
  font-family: Mint Grotesk Display;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  background-color: var(--gray-one-off);
  border: 1px solid var(--black-one-off);
  min-width: 208px;
  min-height: 38px;
  margin-bottom: 35px;
  cursor: pointer;
}

/* Mobile */
@media only screen and (max-width: 768px) {
  .modal_div {
    margin: 10px !important;
  }
}
