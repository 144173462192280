/*
You do not need to worry about .error {} colliding with any other `.css` or
`.module.css` files!
*/
.container_div {
  display: block;
  width: 100%;
  text-align: center;
  height: 30px;
  min-height: 30px;
  height: auto;
  background-color: var(--green-one-off);
}

.announcements_div {
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: block;
  width: 100%;
}

.announcements_link {
  color: var(--black-one-off);
  text-align: center;
  font-family: Mint Grotesk Display Light;
  font-size: 11px;
  font-style: normal;
  line-height: normal;
}

.announcements_link_separator {
  color: var(--black-one-off);
  font-family: Mint Grotesk Display Light;
  font-size: 11px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.385px;
}

.closeButton {
  color: var(--black-one-off);
  font-family: Mint Grotesk Display Light;
  font-size: 10px;
  font-style: normal;
  line-height: normal;
  position: absolute;
  right: 1%;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
}

.closeButton:after {
  content: 'CLOSE [X]';
}

/* Mobile */
@media only screen and (max-width: 768px) {
  .closeButton:after {
    content: '[X]' !important;
  }

  .announcements_link {
    font-size: auto !important;
    padding: 20px !important;
  }
}
