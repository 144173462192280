:root {
    --green-one-off: #B9FAA7;
    --black-one-off: #141414;
    --white-one-off: #FFF;
    --white-bone-one-off: #FFFFF8;
    --white-placeholder-one-off: #FCFBFB;
    --red-one-off: #F00;
    --gray-one-off: #F4F4F4;
    --gray-normal-one-off: #BFBFBF;
    --gray-medium-one-off: #B5B5B5;
    --gray-d4-one-off: #475367;
    --gray-d3-one-off: #D3D3D3;
    --gray-d2-one-off: #E3E3E3;
    --gray-d1-one-off: #D1D1D1;
}