.navBar {
  background-color: rgba(255, 255, 255, 0) !important;
  overflow: visible !important;
  width: 100% !important;
  position: absolute !important;
  top: auto;
  z-index: 1 !important;
  color: var(--white-one-off) !important;
  margin: 0 !important;
  padding: 0 !important;
}
