/*
You do not need to worry about .error {} colliding with any other `.css` or
`.module.css` files!
*/
.modal {
  position: fixed;
  bottom: 2%;
  right: 1%;
  height: 78px;
  background-color: var(--white-one-off);
  border-radius: 5px;
  z-index: 200;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 600px;
  color: var(--black-one-off);
}

.modal_close {
  color: var(--black-one-off);
  font-family: Mint Grotesk Display !important;
  font-size: 10px;
  font-style: normal;
  line-height: normal;
  position: relative;
  cursor: pointer;
}

.modal_div_header {
  display: flex;
  justify-content: flex-end;
}

.modal_div_icon > svg {
  display: block;
  width: 32px;
}

.modal_div_text {
  /* min-width: 400px; */
  text-align: left;
  font-family: Mint Grotesk Display;
  font-size: 14px;
  font-style: normal;
  line-height: 17px;
  text-transform: math-auto;
}

/* Mobile */
@media only screen and (max-width: 768px) {
  .modal {
    margin-left: 5px !important;
    margin-right: 5px !important;
    width: -webkit-fill-available !important;
  }

  .modal_close > span {
    display: none;
  }
}
