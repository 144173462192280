/*
You do not need to worry about .error {} colliding with any other `.css` or
`.module.css` files!
*/

.showCardBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: Mint Grotesk Display;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  cursor: pointer;
}

.boxCart {
  z-index: 109;
  display: block;
  position: fixed;
  top: 0%;
  right: 0%;
  background-color: rgba(20, 20, 20, 0.4);
  width: 100%;
  height: 100%;
}

.cartTitle {
  color: var(--black-one-off);
  font-family: Mint Grotesk Display;
  font-size: 19px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
}

.cartClose {
  color: var(--black-one-off);
  font-family: Mint Grotesk Display;
  font-size: 10px;
  font-style: normal;
  line-height: normal;
  cursor: pointer;
}

.itemsContainer {
  width: 100%;
  flex-flow: column;
  justify-content: space-between;
  gap: 1px;
  overflow-y: auto;
  overflow-x: hidden;
}

.itemsContainer_item {
  width: 404px;
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: var(--white-one-off);
  justify-content: space-between;
  gap: 0;
}
.itemsContainer_item:not(:last-child) {
  border-bottom: 1px solid #E9E9E9;
}

.itemsContainer_item_image {
  width: 170px;
  height: 170px;
  background-color: var(--white-placeholder-one-off);
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.itemsContainer_item_desc {
  width: 60%;
  padding-left: 20px;
  flex-flow: column;
  color: var(--black-one-off);
  font-family: Mint Grotesk Display;
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  gap: 30px;
  justify-content: space-between;
}

.itemsContainer_item_desc > span:nth-child(1) {
  max-width: 180px;
}

.itemsContainer_item_desc > span:nth-child(2)::after {
  content: ' USD';
}

.itemsContainer_item_act {
  color: var(--gray-d3-one-off);
  font-family: Mint Grotesk Display Light;
  font-size: 10px;
  font-style: normal;
  line-height: normal;
  padding-top: 23px;
  gap: 14px;
  flex-flow: column;
}

.itemsContainer_item_act > span:nth-child(2) {
  font-family: Mint Grotesk Display;
  text-decoration-line: underline;
  cursor: pointer;
  width: fit-content;
}

.linkRight {
  width: 100px !important;
}
