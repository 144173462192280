/*
You do not need to worry about .error {} colliding with any other `.css` or
`.module.css` files!
*/
.modal {
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 200;
  background-color: rgba(20, 20, 20, 0.4);
  overflow-y: hidden;
}

.modal_div {
  gap: 17px;
  display: Flex;
  flex-flow: column;
  position: relative;
  height: auto;
  width: 415px;
  background-color: var(--white-one-off);
  border-radius: 5px;
  padding: 20px 30px 20px 30px;
}

.title {
  color: var(--black-one-off);
  font-family: Mint Grotesk Display Black;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.close {
  color: var(--black-one-off);
  font-family: Mint Grotesk Display;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.modal_div_body {
  gap: 36px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.modal_div_body > p {
  padding: 0;
  margin: 0;
  color: var(--black-one-off);
  font-family: Mint Grotesk Display Light;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}

.modal_div_body > p > a {
  color: var(--black-one-off);
  font-family: Mint Grotesk Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  text-decoration-line: underline;
  cursor: pointer;
  text-underline-position: under;
}

.agree {
  color: var(--black-one-off);
  padding: 10px;
  background-color: var(--gray-one-off);
  border: 1px solid var(--black-one-off);
  text-align: center;
  font-family: Mint Grotesk Display Black;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  width: 60%;
}

.decline {
  color: var(--gray-d2-one-off);
  padding: 10px;
  text-align: center;
  font-family: Mint Grotesk Display Black;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  width: 40%;
}

/* Mobile */
@media only screen and (max-width: 768px) {
  .modal_div {
    margin: 10px !important;
  }
}
